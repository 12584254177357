
<template>
   <div>
      <div class="flex items-center w-full p-4 bg-primary text-white top-header-page" @click.stop="back()">
         <feather-icon icon="ChevronLeftIcon"  class="cursor-pointer mr-4" @click.stop="back()"></feather-icon>
         <h4 class="text-white">{{ listLayanan.name }}</h4>
      </div>
      <vx-card title-color="primary" class="mb-base">
         <ul class="bordered-items">
            <li v-for="layanan in listLayanan.tasks" :key="layanan.id" class="py-2 flex justify-between" @click.stop="toItem(layanan)">
               <span>{{ layanan.task_title }}</span>
               <feather-icon icon="ChevronRightIcon"  class="cursor-pointer mr-4"></feather-icon>
            </li>
         </ul>
      </vx-card>
   </div>
</template>

<script>

export default {
   data () {
      return {
         listLayanan: []
      }
   },
   components: {
   },
   computed: {
   },
   created () {
      this.$store.commit('navbarUpdateStatus', true)
   },
   mounted() {
      this.listLayanan = this.$route.params.tasks;
   },
   methods: {
      back () {
         this.$router.go(-1);
      },
      toItem (item) {
         let layanan = this.listLayanan
         this.$router.push({
         name: "LayananItem",
         params: { item, layanan }
         });
      }
   }
}
</script>

<style scoped>
</style>
